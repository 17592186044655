import * as t from "io-ts";
import { LicenseStatusEnumV } from "@type/core/license/status.types";

export const licenseDetailsResponseDtoDecoderContent = {
  id: t.string,
  key: t.string,
  durationUnit: t.string,
  durationValue: t.number,
  status: LicenseStatusEnumV,
  statusDate: t.string,
  activationDate: t.union([t.string, t.null]),
  expirationDate: t.union([t.string, t.null]),
};

export const licenseDetailsResponseDtoDecoder = t.type(
  licenseDetailsResponseDtoDecoderContent,
);

export type LicenseDetailsResponseDto = t.TypeOf<
  typeof licenseDetailsResponseDtoDecoder
>;
