import { JSX } from "react";
import { Navigate, Outlet } from "react-router";
import { ROUTES } from "@routes/Routes";
import configuration from "@utils/Config";

interface EnvironmentLayoutProps {
  allowedEnvironments: string[];
}

const EnvironmentLayout = ({
  allowedEnvironments,
}: EnvironmentLayoutProps): JSX.Element => {
  const currentEnvironment = configuration.environment;
  if (!allowedEnvironments.includes(currentEnvironment)) {
    return <Navigate to={ROUTES.connected.home.generate()} />;
  }

  return <Outlet />;
};

export default EnvironmentLayout;
