import * as t from "io-ts";
import { LicenseStatusEnumV } from "@type/core/license/status.types";

export const supervisedLicenseItemResponseDtoDecoderContent = {
  key: t.string,
  status: LicenseStatusEnumV,
  durationUnit: t.string,
  durationValue: t.number,
  activationDate: t.union([t.string, t.null]),
  expirationDate: t.union([t.string, t.null]),
  managerFirstName: t.union([t.string, t.null]),
  managerLastName: t.union([t.string, t.null]),
  managerEmail: t.union([t.string, t.null]),
  holderFirstName: t.union([t.string, t.null]),
  holderLastName: t.union([t.string, t.null]),
  holderEmail: t.union([t.string, t.null]),
};

export const supervisedLicenseItemResponseDtoDecoder = t.type(
  supervisedLicenseItemResponseDtoDecoderContent,
);

export type SupervisedLicenseITemResponseDto = t.TypeOf<
  typeof supervisedLicenseItemResponseDtoDecoder
>;
