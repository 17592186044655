import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restCreationMultipartHandler,
  restListHandler,
  restPostHandler,
} from "@utils/rest/RestClient";
import { LicenseImportRequestDto } from "@state/licenses/dto/request/license.import.request.dto";
import * as t from "io-ts";
import {
  UserLicenseResponseDto,
  userLicenseResponseDtoDecoder,
} from "@state/licenses/dto/response/user.license.response.dto";
import {
  LicenseDetailsResponseDto,
  licenseDetailsResponseDtoDecoder,
  licenseDetailsResponseDtoDecoderContent,
} from "@state/licenses/dto/response/license.details.response.dto";
import {
  SupervisedLicenseITemResponseDto,
  supervisedLicenseItemResponseDtoDecoder,
} from "@state/licenses/dto/response/supervised.license.item.response.dto";
import { SuperviseLicenseRequestDto } from "@state/licenses/dto/request/supervise.license.request.dto";

const licenseBaseUrl = `${configuration.backendBaseUrl}/licenses`;
const importLicenseByKeyUrl = `${licenseBaseUrl}/add`;
const importLicenseByFileUrl = `${licenseBaseUrl}/import`;

const LicenseManagedResponseDtoArrayDecoder = t.array(
  userLicenseResponseDtoDecoder,
);

export const requestImportLicenseByKey = createEffect({
  handler: restPostHandler<
    LicenseImportRequestDto,
    LicenseDetailsResponseDto,
    typeof licenseDetailsResponseDtoDecoderContent,
    typeof licenseDetailsResponseDtoDecoder
  >(importLicenseByKeyUrl, undefined, licenseDetailsResponseDtoDecoder),
});

export const requestGetManagedLicenses = createEffect({
  handler: restListHandler<
    UserLicenseResponseDto,
    Record<string, unknown>,
    typeof userLicenseResponseDtoDecoder,
    typeof LicenseManagedResponseDtoArrayDecoder
  >(licenseBaseUrl + "/managed"),
});

export const requestGetHeldLicenses = createEffect({
  handler: restListHandler<
    UserLicenseResponseDto,
    Record<string, unknown>,
    typeof userLicenseResponseDtoDecoder,
    typeof LicenseManagedResponseDtoArrayDecoder
  >(licenseBaseUrl + "/held"),
});

export const requestRejectLicense = createEffect({
  handler: restPostHandler<
    null,
    null,
    typeof licenseDetailsResponseDtoDecoderContent,
    typeof licenseDetailsResponseDtoDecoder
  >(licenseBaseUrl + "/", "/reject", licenseDetailsResponseDtoDecoder),
});

export const requestImportLicenseByFile = createEffect({
  handler: restCreationMultipartHandler<UserLicenseResponseDto>(
    importLicenseByFileUrl,
  ),
});

export const requestAttributeLicense = createEffect({
  handler: restPostHandler<
    null,
    UserLicenseResponseDto,
    typeof licenseDetailsResponseDtoDecoderContent,
    typeof licenseDetailsResponseDtoDecoder
  >(licenseBaseUrl + "/", undefined, licenseDetailsResponseDtoDecoder),
});

export const requestAttributeLicenseInQueue = createEffect({
  handler: restPostHandler<
    null,
    UserLicenseResponseDto,
    typeof licenseDetailsResponseDtoDecoderContent,
    typeof licenseDetailsResponseDtoDecoder
  >(licenseBaseUrl + "/", undefined, licenseDetailsResponseDtoDecoder),
});

export const requestRemoveLicense = createEffect({
  handler: restPostHandler<
    null,
    UserLicenseResponseDto,
    typeof licenseDetailsResponseDtoDecoderContent,
    typeof licenseDetailsResponseDtoDecoder
  >(licenseBaseUrl + "/", "/unassign", licenseDetailsResponseDtoDecoder),
});

const SupervisedLicenseItemResponseDtoArrayDecoder = t.array(
  supervisedLicenseItemResponseDtoDecoder,
);

export const requestGetSupervisedLicenses = createEffect({
  handler: restListHandler<
    SupervisedLicenseITemResponseDto,
    Record<string, unknown>,
    typeof supervisedLicenseItemResponseDtoDecoder,
    typeof SupervisedLicenseItemResponseDtoArrayDecoder
  >(licenseBaseUrl + "/supervise"),
});

export const requestSuperviseLicenseByKey = createEffect({
  handler: restPostHandler<SuperviseLicenseRequestDto>(
    licenseBaseUrl + "/supervise",
  ),
});

export const requestSuperviseLicenseByFile = createEffect({
  handler: restCreationMultipartHandler(licenseBaseUrl + "/supervise/import"),
});
