import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import SubmitButton from "@components/core/buttons/SubmitButton";
import InputFormField from "@components/core/inputs/InputFormField";
import { passwordHelper } from "@utils/helpers/password-helper";
import PasswordValidationTooltip from "@components/core/tooltips/PasswordValidationTooltip";
import { requestRegistration } from "@state/auth/AuthEffects";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import { FIELD_LENGTH, formValidateTriggers } from "@utils/Constant";
import { toastError } from "@utils/helpers/toast-helper";
import { ROUTES } from "@routes/Routes";
import MailFormField from "@components/core/inputs/MailFormField";
import { SelectFormField } from "@components/core/inputs/SelectFormField";
import {
  findUserProfile,
  PROFILES_OPTIONS,
  UserProfile,
} from "@utils/enums/profile.enum";
import LoginLayout from "@components/core/layouts/LoginLayout";
import LinkButton from "@components/core/links/LinkButton";
import i18n from "@/utils/i18n/i18n";
import CguFormField from "@components/core/inputs/CguFormField";
import PasswordFormField from "@components/core/inputs/PasswordFormField";

interface RegistrationFormData {
  firstName: string;
  lastName: string;
  profile: UserProfile;
  ordinalNumber: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
  cguAccepted: boolean;
}

const initialFormValues = {
  firstName: "",
  lastName: "",
  profile: "",
  ordinalNumber: "",
  email: "",
  phoneNumber: "",
  password: "",
  passwordConfirmation: "",
  cguAccepted: false,
};

const defaultPasswordStrengthConstraints = {
  minCharacters: 8,
  minLowerCaseLetters: 1,
  minUpperCaseLetters: 1,
  minDigits: 1,
  minSpecialCharacters: 1,
} as PasswordStrengthConstraintsDetailsResponseDto;

const acceptedUserProfiles: string[] = [
  UserProfile.VETERINARY,
  UserProfile.OTHER,
  UserProfile.DEVELOPER,
];
const userProfileOptions = PROFILES_OPTIONS.filter((option) =>
  acceptedUserProfiles.includes(option.value),
);

const RegistrationScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [selectedProfile, setSelectedProfile] = useState<
    UserProfile | undefined
  >();

  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    return requestRegistration.done.watch(({ result }) => {
      const emailField = form.getFieldValue("email") as string;
      const profile = form.getFieldValue("profile") as string;
      if (result.ok) {
        void navigate(
          ROUTES.auth.login.generate({
            registration: "true",
            email: email,
            profile: profile,
          }),
        );
      } else if (result.responseCode === 409) {
        toastError(
          t("registration.form.messages.conflict", {
            login: emailField,
          }),
        );
      } else {
        toastError(t("registration.form.messages.error"));
      }
      setButtonLoading(false);
    });
  });

  const onProfileChange = (value: string | string[]): void => {
    form.setFields([
      {
        name: "ordinalNumber",
        value: "",
        errors: [],
      },
    ]);
    if (Array.isArray(value)) {
      return;
    }

    setSelectedProfile(findUserProfile(value));
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (
      value === "" ||
      passwordHelper.validate(value, defaultPasswordStrengthConstraints)
    ) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  const handleSubmit = (values: RegistrationFormData) => {
    setButtonLoading(true);

    if (!passwordValidationFailed) {
      void requestRegistration({
        dto: {
          firstName: values.firstName,
          lastName: values.lastName,
          profile: values.profile,
          ordinalNumber: values.ordinalNumber,
          email: values.email,
          phoneNumber: values.phoneNumber,
          password: values.password,
          cguAccepted: values.cguAccepted,
        },
      });
    } else {
      setButtonLoading(false);
    }
  };

  return (
    <LoginLayout title={t("registration.title")}>
      <Form
        onFinish={handleSubmit}
        initialValues={initialFormValues}
        form={form}
        {...formValidateTriggers}
        layout="vertical"
      >
        <InputFormField
          form={form}
          module="registration.form"
          field="lastName"
          required
        />
        <InputFormField
          form={form}
          module="registration.form"
          field="firstName"
          required
        />
        <SelectFormField
          form={form}
          module="registration.form"
          field="profile"
          required
          options={userProfileOptions}
          onChange={onProfileChange}
        />
        <InputFormField
          form={form}
          module="registration.form"
          field="ordinalNumber"
          required={selectedProfile == UserProfile.VETERINARY}
          disabled={selectedProfile !== UserProfile.VETERINARY}
        />
        <MailFormField
          form={form}
          module="registration.form"
          field="email"
          onChange={() => {
            setEmail(form.getFieldValue("email") as string);
          }}
          required
        />
        <InputFormField
          form={form}
          module="registration.form"
          field="phoneNumber"
          required={false}
          maxLength={FIELD_LENGTH.PHONE_NUMBER}
          rules={[
            {
              pattern: /^\+?(\(\d{3}\)|\d{3})[-\s.]?\d{3}[-\s.]?\d{4,6}$/,
              message: (
                <span>{i18n.t("forms.errors.phone-number-invalid")}</span>
              ),
            },
          ]}
        />
        <PasswordFormField
          module="registration.form"
          field="password"
          required
          onChange={onPasswordChange}
        />
        {passwordValidationFailed && (
          <div data-test="error-password">
            <PasswordValidationTooltip
              password={password}
              strengthConstraints={defaultPasswordStrengthConstraints}
            />
          </div>
        )}
        <PasswordFormField
          module="registration.form"
          field="passwordConfirmation"
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    t(
                      "registration.form.fields.passwordConfirmation.errors.unmatch",
                    ),
                  ),
                );
              },
            }),
          ]}
        />
        <CguFormField />
        <div className="pt-20 d-flex flex-column align-items-stretch gap-24">
          <SubmitButton
            module="registration.form"
            isSubmitting={buttonLoading}
            label="submit"
          />
          <LinkButton
            to={ROUTES.auth.login.generate()}
            variant="transparent"
            text={t("registration.form.actions.login")}
            className="text-center"
          />
        </div>
      </Form>
    </LoginLayout>
  );
};

export default RegistrationScreen;
