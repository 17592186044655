import { useTranslation } from "react-i18next";
import { Spin, Table, TablePaginationConfig } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table/interface";
import {
  ColumnGroupType,
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import { AnyObject } from "antd/es/_util/type";

interface BasicTableProps<T> {
  id?: string;
  isLoading?: boolean;
  rowSelection?: TableRowSelection<T>;
  rowKey?: (record: T) => string;
  onRow?: (record: T) => AnyObject;
  rowClassName?: (record: T) => string;
  dataSource: readonly T[];
  columns: (ColumnGroupType<T> | ColumnType<T>)[] | ColumnsType<T>;
  pagination?: false | TablePaginationConfig;
  showSorterTooltip?: boolean;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => void;
  className?: string;
}

const BasicTable = <T extends AnyObject>(props: BasicTableProps<T>) => {
  const {
    id,
    isLoading = false,
    rowSelection,
    rowKey,
    onRow,
    rowClassName,
    dataSource,
    columns,
    pagination = false,
    showSorterTooltip = false,
    onChange,
    className = "",
  } = props;
  const { t } = useTranslation();

  return (
    <Table
      className={`app-table ${className}`}
      id={id}
      locale={{
        emptyText: isLoading
          ? t("table.emptyText.loading")
          : t("table.emptyText.noData"),
        triggerAsc: t("table.sort.asc"),
        triggerDesc: t("table.sort.desc"),
        cancelSort: t("table.sort.cancel"),
      }}
      loading={{ spinning: isLoading, indicator: <Spin /> }}
      showSorterTooltip={showSorterTooltip}
      onChange={onChange}
      rowSelection={rowSelection}
      rowKey={rowKey}
      onRow={onRow}
      pagination={pagination}
      columns={columns}
      dataSource={dataSource}
      rowClassName={(record: T) =>
        `${onRow ? "cursor-pointer" : ""} ${rowClassName ? rowClassName(record) : ""}`
      }
    />
  );
};

export default BasicTable;
