import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/lib/form/Form";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import {
  requestUpdatePassword,
  requestGetPasswordStrengthConstraints,
} from "@state/auth/AuthEffects";
import { passwordHelper } from "@utils/helpers/password-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import { logout } from "@state/auth/AuthEvents";
import { formHelper } from "@utils/helpers/form-helper";
import { Form } from "antd";
import { formValidateTriggers } from "@utils/Constant";
import InputFormField from "@components/core/inputs/InputFormField";
import PasswordValidationTooltip from "@components/core/tooltips/PasswordValidationTooltip";
import BasicButton from "@components/core/buttons/BasicButton";
import SubmitButton from "@components/core/buttons/SubmitButton";
import { ROUTES } from "@routes/Routes";

interface UpdatePasswordFormData {
  previousCredential: string;
  credential: string;
  confirmCredential: string;
}

const prefix = "account.set-password";

const UpdatePasswordForm: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = useForm();

  const InitialValues = {
    previousCredential: "",
    credential: "",
    confirmCredential: "",
  };

  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordStrengthConstraints, setPasswordStrengthConstraints] =
    useState<PasswordStrengthConstraintsDetailsResponseDto>();

  useEffect(() => {
    void requestGetPasswordStrengthConstraints({ id: "" }).then((result) => {
      setPasswordStrengthConstraints(result.data);
    });
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (
      value === "" ||
      (passwordStrengthConstraints &&
        passwordHelper.validate(value, passwordStrengthConstraints))
    ) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  const handleSubmit = (values: UpdatePasswordFormData) => {
    requestUpdatePassword({
      dto: {
        previousCredential: values.previousCredential,
        credential: values.credential,
        confirmCredential: values.confirmCredential,
      },
    })
      .then((result: ParsedResponse<void>) => {
        if (result.ok) {
          toastSuccess(t(`${prefix}.form.messages.success`));
          logout();
          void navigate(ROUTES.auth.login.generate());
        } else {
          formHelper.handleFormErrors(`${prefix}.fields`, form, result);
        }
      })
      .catch((error: unknown) => {
        toastError((error as Error).message);
      });
  };

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      initialValues={InitialValues}
      {...formValidateTriggers}
      layout="vertical"
    >
      <InputFormField
        form={form}
        module={prefix}
        type="password"
        field="previousCredential"
        required
      />
      <InputFormField
        form={form}
        onChange={onChange}
        module={prefix}
        type="password"
        field="credential"
        required
      />
      {passwordValidationFailed && passwordStrengthConstraints && (
        <div data-test="error-credential">
          <PasswordValidationTooltip
            password={password}
            strengthConstraints={passwordStrengthConstraints}
          />
        </div>
      )}
      <InputFormField
        form={form}
        module={prefix}
        type="password"
        field="confirmCredential"
        className="mb-16"
        rules={[
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("credential") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t(`${prefix}.fields.credential.errors.unmatch`)),
              );
            },
          }),
        ]}
        required
      />
      <div className="pt-8 d-flex flex-column align-items-stretch gap-24">
        <SubmitButton
          module={`${prefix}.form`}
          isSubmitting={false}
          label="save"
        />
        <BasicButton
          text={t("buttons.back")}
          variant="transparent"
          onClick={() => {
            void navigate(ROUTES.connected.account.infos.generate());
          }}
        />
      </div>
    </Form>
  );
};

export default UpdatePasswordForm;
