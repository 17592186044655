import { FunctionComponent } from "react";
import PageLayout from "@components/core/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/core/buttons/BasicButton";
import { useNavigate } from "react-router";
import { ROUTES } from "@routes/Routes";

const MaintenanceScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout
      container={false}
      contentInCard
      bodyClassName="app-maintenance-screen h-100 d-flex align-items-center justify-content-center"
    >
      <>
        <div className="py-12">
          <h1>{t("maintenance.siteInMaintenance")}</h1>
          <p className="m-0">{t("maintenance.notAccessible1")}</p>
          <p className="m-0">{t("maintenance.notAccessible2")}</p>
          <BasicButton
            text={t("maintenance.reload")}
            onClick={() => {
              void navigate(ROUTES.connected.home.generate());
            }}
            className="mt-24"
          />
        </div>
      </>
    </PageLayout>
  );
};

export default MaintenanceScreen;
