import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { UserLicenseResponseDto } from "@state/licenses/dto/response/user.license.response.dto";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import BasicTable from "@components/core/tables/BasicTable";
import { Form, TablePaginationConfig } from "antd";
import { formValidateTriggers } from "@utils/Constant";
import {
  columns as licensesManagementColumns,
  computeOrdinalNumberField,
} from "@views/core/admin/licenses/columns/LicensesManagementColumns";
import Section from "@components/core/section/Section";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "@utils/helpers/toast-helper";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import {
  requestAttributeLicense,
  requestAttributeLicenseInQueue,
  requestGetManagedLicenses,
  requestImportLicenseByFile,
  requestImportLicenseByKey,
  requestRejectLicense,
  requestRemoveLicense,
} from "@state/licenses/LicensesEffects";
import { useForm } from "antd/lib/form/Form";
import { formHelper } from "@utils/helpers/form-helper";
import { licenseColorVariant } from "@views/core/admin/licenses/LicensesScreen";
import { showConfirm } from "@components/core/modals/ConfirmModal";
import LicenseKeyField from "@components/core/inputs/LicenseKeyField";
import { readFileAsFormData } from "@utils/helpers/file-helper";
import ImportFileButton from "@components/core/buttons/ImportFileButton";

const LicensesManagement: FunctionComponent = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [managedLicencesLoaded, setManagedLicencesLoaded] =
    useState<boolean>(true);

  const [managedLicenses, setManagedLicenses] = useState<
    UserLicenseResponseDto[]
  >([]);

  const [sorter, setSorter] = useState<
    | SorterResult<UserLicenseResponseDto>
    | SorterResult<UserLicenseResponseDto>[]
  >();

  const handleManagedLicensesTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<UserLicenseResponseDto>
      | SorterResult<UserLicenseResponseDto>[],
  ) => {
    if ((sorter as SorterResult<UserLicenseResponseDto>).column) {
      setSorter(sorter);
    } else {
      setSorter({});
    }
  };

  const fetchManagedLicenses = useCallback(() => {
    setManagedLicencesLoaded(false);
    void requestGetManagedLicenses({
      sorter,
    }).finally(() => {
      setManagedLicencesLoaded(true);
    });
  }, [sorter]);

  useEffect(() => {
    fetchManagedLicenses();
  }, [fetchManagedLicenses]);

  useEffect(() => {
    return requestGetManagedLicenses.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setManagedLicenses(result.data);
      }
    });
  });

  const handleAttributeLicense = (licenseId: string) => {
    const ordinalNumber = form.getFieldValue(
      `ordinalNumber ${licenseId}`,
    ) as string;
    const ordinalNumberRegex = /^[a-zA-Z0-9]{1,20}$/;
    if (!RegExp(ordinalNumberRegex).exec(ordinalNumber)) {
      toastError(i18n.t("licenses.errors.assign.invalidOrdinalNumber"));
    } else {
      void requestAttributeLicense({
        upperEntityId: licenseId + "/assign/" + ordinalNumber,
      }).then((result) => {
        if (!result.ok) {
          if (result.responseCode === 409) {
            handleAttributeInQueue(licenseId, ordinalNumber);
          } else {
            toastError(t("licenses.errors.assign.unableToAttributeLicense"));
            formHelper.handleFormErrors("licenses.form.fields", form, result);
          }
        } else {
          fetchManagedLicenses();
          if (result.data) {
            form.setFieldValue(computeOrdinalNumberField(result.data.id), "");
          }
        }
      });
    }
  };

  useEffect(() => {
    return requestAttributeLicenseInQueue.done.watch(({ result }) => {
      if (result.ok) {
        fetchManagedLicenses();
        if (result.data) {
          form.setFieldValue(computeOrdinalNumberField(result.data.id), "");
        }
      } else if (result.responseCode === 409) {
        toastError(t("licenses.errors.assign.userHasAlreadyLicenceInQueue"));
      } else {
        toastError(t("licenses.errors.assign.unableToAddLicenseInQueue"));
      }
    });
  });
  const handleAttributeInQueue = (licenseId: string, ordinalNumber: string) => {
    void showConfirm({
      title: i18n.t("licenses.actions.queue.title"),
      content: i18n.t("licenses.actions.queue.content"),
      ok: i18n.t("licenses.actions.queue.ok"),
    }).then((confirmed) => {
      if (confirmed) {
        void requestAttributeLicenseInQueue({
          upperEntityId: licenseId + "/assign/" + ordinalNumber + "/queue",
        });
      }
    });
  };

  useEffect(() => {
    return requestRemoveLicense.done.watch(({ result }) => {
      if (result.ok && result.data) {
        fetchManagedLicenses();
      } else if (!result.ok) {
        toastError(
          t("licenses.errors.remove", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  const handleRemoveLicense = (licenseId: string) => {
    void requestRemoveLicense({
      upperEntityId: licenseId,
    });
  };

  useEffect(() => {
    return requestRejectLicense.done.watch(() => {
      fetchManagedLicenses();
    });
  });

  useEffect(() => {
    return requestImportLicenseByFile.done.watch(({ result }) => {
      if (result.ok) {
        fetchManagedLicenses();
        if (result.responseCode === 200) {
          toastSuccess(t(`licenses.success.import-by-file`));
        } else {
          toastWarning(t(`licenses.warning.import-by-file`));
        }
      } else {
        toastError(
          t("licenses.errors.import.file", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  const handleFileSubmit = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileFormData = await readFileAsFormData(event);
    void requestImportLicenseByFile({
      dto: fileFormData,
    });
  };

  const handleSubmit = (licenseKey?: string) => {
    if (licenseKey) {
      importLicenseByKey();
    } else {
      toastError(t("licenses.errors.import.emptyKey"));
    }
  };

  const importLicenseByKey = useCallback(() => {
    const key = form.getFieldValue("licenseNumber") as string;
    void requestImportLicenseByKey({
      dto: {
        key: key,
      },
    });
  }, [form]);

  useEffect(() => {
    return requestImportLicenseByKey.done.watch(({ result }) => {
      if (result.ok && result.data) {
        fetchManagedLicenses();
        form.setFieldValue("licenseNumber", "");
        toastSuccess(t("licenses.success.import"));
      } else if (!result.ok) {
        toastError(
          t("licenses.errors.import.key", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  return (
    <Section
      title={t("licenses.licensesManagement.title")}
      className="mb-48"
      rightContent={
        <ImportFileButton
          id="importFileInput"
          text={t("licenses.licensesManagement.import")}
          handleFileSubmit={(e) => void handleFileSubmit(e)}
        />
      }
    >
      <Form
        {...formValidateTriggers}
        form={form}
        layout="vertical"
        className="table-licenses-management"
      >
        <>
          <BasicTable
            dataSource={managedLicenses}
            columns={licensesManagementColumns(
              form,
              handleAttributeLicense,
              handleRemoveLicense,
            )}
            rowKey={(record: UserLicenseResponseDto) => record.id}
            rowClassName={(record: UserLicenseResponseDto) =>
              `row-${licenseColorVariant(record.status)}`
            }
            isLoading={!managedLicencesLoaded}
            onChange={handleManagedLicensesTableChange}
          />
          <LicenseKeyField
            form={form}
            module="licenses.form"
            field="licenseNumber"
            onClick={(key?: string) => {
              handleSubmit(key);
            }}
          />
        </>
      </Form>
    </Section>
  );
};

export default LicensesManagement;
