import i18n from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import IconMore from "@static/core/img/icon-more.svg?react";
import { FormInstance, Popover } from "antd";
import InputFormField from "@components/core/inputs/InputFormField";
import BasicButton from "@components/core/buttons/BasicButton";
import { CheckOutlined } from "@ant-design/icons";
import { showConfirm } from "@components/core/modals/ConfirmModal";
import { UserLicenseResponseDto } from "@state/licenses/dto/response/user.license.response.dto";
import { LicenseStatus } from "@utils/enums/license.enum";
import {
  renderPeriodCell,
  renderRemainingDurationCell,
} from "@views/core/admin/licenses/columns/DurationCells";

export const computeOrdinalNumberField = (ordinalNumber: string): string => {
  return `ordinalNumber ${ordinalNumber}`;
};

const computeRemoveModalContent = (record: UserLicenseResponseDto) => {
  const warningShouldBeDisplayed = record.status === LicenseStatus.ASSIGNED;

  const modalContent = i18n.t("licenses.actions.remove.content", {
    name: `${record.holderFirstName} ${record.holderLastName}`,
    ordinalNumber: record.ordinalNumber,
  });

  return (
    <div>
      {modalContent}
      {warningShouldBeDisplayed && (
        <div>
          {i18n.t("licenses.actions.remove.warning")}
          <br />
          {i18n.t("licenses.actions.remove.warningFooter")}
        </div>
      )}
    </div>
  );
};

export const columns = (
  form: FormInstance,
  handleAttributeLicense: (licenseId: string) => void,
  handleRemoveLicense: (licenseId: string) => void,
): ColumnsType<UserLicenseResponseDto> => [
  {
    title: i18n.t("licenses.licensesManagement.table.licenseNumber"),
    key: "key",
    dataIndex: "key",
    showSorterTooltip: false,
  },
  {
    title: i18n.t("licenses.licensesManagement.table.duration"),
    key: "durationValue",
    sorter: true,
    dataIndex: "durationValue",
    render: (durationValue: number, record) => {
      return durationValue > 1
        ? i18n.t(`common.${record.durationUnit}_other`, {
            count: durationValue,
          })
        : i18n.t(`common.${record.durationUnit}_one`, { count: durationValue });
    },
  },
  {
    title: i18n.t("licenses.licensesManagement.table.period"),
    key: "period",
    className: "change-color-with-status",
    render: renderPeriodCell,
  },
  {
    title: i18n.t("licenses.licensesManagement.table.remainingDuration"),
    key: "expirationDate",
    className: "change-color-with-status",
    width: 202,
    sorter: true,
    showSorterTooltip: false,
    render: renderRemainingDurationCell,
  },
  {
    title: i18n.t("licenses.licensesManagement.table.assignBy"),
    key: "holderName",
    showSorterTooltip: false,
    sorter: true,
    render: (record: UserLicenseResponseDto) => {
      return record.holderFirstName && record.holderLastName ? (
        `${record.holderFirstName} ${record.holderLastName} (#${record.ordinalNumber})`
      ) : (
        <InputFormField
          form={form}
          module="licenses.form"
          field={computeOrdinalNumberField(record.id)}
          placeholder={i18n.t("licenses.form.fields.ordinalNumber.placeholder")}
          className="mb-0"
          maxLength={20}
          rules={[
            {
              pattern: /^[a-zA-Z0-9]{1,20}$/,
              message: (
                <span>{i18n.t("forms.errors.ordinal-number-invalid")}</span>
              ),
            },
          ]}
        />
      );
    },
  },
  {
    title: "",
    key: "holderName",
    width: 80,
    render: (record: UserLicenseResponseDto) => {
      return record.holderFirstName && record.holderLastName ? (
        <div className="d-flex align-items-center justify-content-end">
          <Popover
            content={
              <>
                <div
                  className={"mb-16 cursor-pointer"}
                  onClick={() => {
                    void showConfirm({
                      title: i18n.t("licenses.actions.remove.title"),
                      content: computeRemoveModalContent(record),
                    }).then((confirmed) => {
                      if (confirmed) {
                        handleRemoveLicense(record.id);
                      }
                    });
                  }}
                >
                  {i18n.t("licenses.actions.unassign")}
                </div>
                <div className={"mt-16 cursor-pointer"}>
                  {i18n.t("licenses.actions.renew")}
                </div>
              </>
            }
            placement="bottomRight"
            trigger="hover"
            arrow={false}
          >
            <IconMore className="cursor-pointer" />
          </Popover>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-end">
          <BasicButton
            size="sm"
            variant="primary-outlined"
            icon={<CheckOutlined />}
            onClick={() => {
              void showConfirm({
                title: i18n.t("licenses.actions.assign.title"),
                content: i18n.t("licenses.actions.assign.content"),
              }).then((confirmed) => {
                if (confirmed) {
                  handleAttributeLicense(record.id);
                }
              });
            }}
          />
        </div>
      );
    },
  },
];
