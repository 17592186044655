import * as t from "io-ts";
import { LicenseStatusEnumV } from "@type/core/license/status.types";

export const userLicenseResponseDtoDecoderContent = {
  id: t.string,
  key: t.string,
  durationUnit: t.string,
  durationValue: t.number,
  status: LicenseStatusEnumV,
  statusDate: t.string,
  ordinalNumber: t.union([t.string, t.null]),
  holderFirstName: t.union([t.string, t.null]),
  holderLastName: t.union([t.string, t.null]),
  managerFirstName: t.union([t.string, t.null]),
  managerLastName: t.union([t.string, t.null]),
  activationDate: t.union([t.string, t.null]),
  expirationDate: t.union([t.string, t.null]),
};

export const userLicenseResponseDtoDecoder = t.type(
  userLicenseResponseDtoDecoderContent,
);

export type UserLicenseResponseDto = t.TypeOf<
  typeof userLicenseResponseDtoDecoder
>;
