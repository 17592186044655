import { ChangeEvent } from "react";
import { RcFile } from "antd/lib/upload";

export const readFileAsFormData = async (
  event: ChangeEvent<HTMLInputElement>,
): Promise<FormData> => {
  return new Promise((resolve) => {
    const files = event.target.files;
    if (files != null && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContentArray = new Uint8Array(
          event.target?.result as ArrayBuffer,
        );

        const fileBlob = new Blob([fileContentArray], {
          type: file.type,
        });
        const formData = new FormData();
        formData.append("file-data", fileBlob, file.name);
        resolve(formData);
      };
      reader.readAsArrayBuffer(file as RcFile);
    }
  });
};
