import App from "./App";
import { BrowserRouter } from "react-router";
import { createRoot } from "react-dom/client";

export const userSupportEmail = "support-utilisateur@sign.vet";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as Element);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
