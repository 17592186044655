import {
  FunctionComponent,
  ReactElement,
  RefObject,
  useEffect,
  useState,
} from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { requestGetCaptchaSiteKey } from "@state/captcha/CaptchaEffects";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { CaptchaSiteKeyResponseDto } from "@state/captcha/dto/response/captcha.site.key.response.dto";

interface Props {
  onVerify: (token: string) => void;
  innerRef: RefObject<HCaptcha | null>;
  className?: string;
}

const Captcha: FunctionComponent<Props> = (props): ReactElement | null => {
  const { onVerify, innerRef, className } = props;

  const [siteKey, setSiteKey] = useState<string>();

  useEffect(() => {
    void requestGetCaptchaSiteKey({}).then(
      (result: ParsedResponse<CaptchaSiteKeyResponseDto>) => {
        if (result.ok && result.data) {
          setSiteKey(result.data.siteKey);
        }
      },
    );
  }, []);

  if (!siteKey) {
    return null;
  }

  return (
    <div className={`d-flex w-100 Captcha ${className ?? ""}`}>
      <HCaptcha
        ref={innerRef}
        sitekey={siteKey}
        onVerify={onVerify}
        size="normal"
      />
    </div>
  );
};

export default Captcha;
