import { Route } from "react-router";
import { ROUTES } from "@routes/Routes";
import LoginScreen from "@views/core/auth/login/LoginScreen";
import ForgotPasswordScreen from "@views/core/auth/password/forgotPassword/ForgotPasswordScreen";
import InitPasswordScreen from "@views/core/auth/password/initPassword/InitPasswordScreen";
import RecoverPasswordScreen from "@views/core/auth/password/recoverPassword/RecoverPasswordScreen";
import RegistrationScreen from "@views/core/auth/registration/RegistrationScreen";
import ConfirmRegistrationScreen from "@views/core/auth/registration/ConfirmRegistrationScreen";
import CookiesScreen from "@views/core/cookie/CookiesScreen";
import ULinkScreen from "@views/core/auth/uLink/ULinkScreen";
import MyAccountScreen from "@views/core/admin/account/infos/MyAccountScreen";
import AuthLayout from "@components/core/routes/AuthLayout";
import UpdatePasswordScreen from "@views/core/admin/account/updatePassword/UpdatePasswordScreen";
import DashboardScreen from "@views/core/admin/home/DashboardScreen";
import ExpiredPasswordScreen from "@views/core/auth/password/expiredPassword/ExpiredPasswordScreen";
import EnvironmentLayout from "@components/core/routes/EnvironmentLayout";
import SelectUserScreen from "@views/core/e2e/users/SelectUserScreen";
import MaintenanceScreen from "@views/core/maintenance/MaintenanceScreen";
import Error404Screen from "@views/core/error404/Error404Screen";
import LicensesScreen from "@views/core/admin/licenses/LicensesScreen";
import DisplayDocumentContainer from "@views/core/document/DisplayDocumentContainer";
import UnauthorizedScreen from "@views/core/auth/login/UnauthorizedScreen";
import CheckAuthenticityScreen from "@views/core/public/document/CheckAuthenticityScreen";
import ComponentsScreen from "@views/core/public/components/ComponentsScreen";
import SsoLoginScreen from "@views/core/deeplink/SsoLoginScreen";
import EnrollmentScreen from "@views/core/deeplink/EnrollmentScreen";

const coreRoutes = (
  <>
    <Route path="*" element={<Error404Screen />} />
    <Route path={ROUTES.auth.login.generate()} element={<LoginScreen />} />
    <Route
      path={ROUTES.auth.forgotPassword.generate()}
      element={<ForgotPasswordScreen />}
    />
    <Route
      path={ROUTES.auth.initPassword.generate()}
      element={<InitPasswordScreen />}
    />
    <Route
      path={ROUTES.auth.recoverPassword.generate()}
      element={<RecoverPasswordScreen />}
    />
    <Route
      path={ROUTES.auth.registration.generate()}
      element={<RegistrationScreen />}
    />
    <Route
      path={ROUTES.auth.registrationConfirmation.generate()}
      element={<ConfirmRegistrationScreen />}
    />
    <Route
      path={ROUTES.auth.unauthorized.generate()}
      element={<UnauthorizedScreen />}
    />
    <Route path={ROUTES.cookie.generate()} element={<CookiesScreen />} />
    <Route path={ROUTES.auth.uLink.generate()} element={<ULinkScreen />} />

    <Route
      path={ROUTES.deeplink.auth.sso.generate()}
      element={<SsoLoginScreen />}
    />
    <Route
      path={ROUTES.deeplink.enrollment.sso.generate()}
      element={<EnrollmentScreen type={"sso"} />}
    />
    <Route
      path={ROUTES.deeplink.enrollment.idCard.generate()}
      element={<EnrollmentScreen type={"idCard"} />}
    />

    <Route element={<AuthLayout />}>
      <Route
        path={ROUTES.connected.home.generate()}
        element={<DashboardScreen />}
      />

      <Route
        path={ROUTES.connected.licenses.generate()}
        element={<LicensesScreen />}
      />

      <Route
        path={ROUTES.connected.account.infos.generate()}
        element={<MyAccountScreen />}
      />
      <Route
        path={ROUTES.connected.account.updatePassword.generate()}
        element={<UpdatePasswordScreen />}
      />
      <Route
        path={ROUTES.connected.account.expiredPassword.generate()}
        element={<ExpiredPasswordScreen />}
      />
    </Route>

    <Route
      element={
        <EnvironmentLayout allowedEnvironments={["e2e", "integration"]} />
      }
    >
      <Route
        path={ROUTES.e2e.users.generate()}
        element={<SelectUserScreen />}
      />
    </Route>

    <Route
      path={ROUTES.maintenance.generate()}
      element={<MaintenanceScreen />}
    />
    <Route path={ROUTES.error404.generate()} element={<Error404Screen />} />
    <Route
      path={ROUTES.documentView.generate(":transactionId", ":documentId")}
      element={<DisplayDocumentContainer />}
    />
    <Route
      path={ROUTES.public.document.checkAuthenticity.generate()}
      element={<CheckAuthenticityScreen />}
    />
    <Route
      path={ROUTES.public.components.generate()}
      element={<ComponentsScreen />}
    />
  </>
);

export default coreRoutes;
