import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/core/layouts/PageLayout";
import BasicCard from "@components/core/cards/BasicCard";
import MyAuthorizations from "@views/core/admin/licenses/MyAuthorizations";
import MyLicenses from "@views/core/admin/licenses/MyLicenses";
import LicensesManagement from "@views/core/admin/licenses/LicensesManagement";
import { LicenseStatus } from "@utils/enums/license.enum";
import { isProfileAmong } from "@utils/helpers/profile-helper";
import { UserProfile } from "@utils/enums/profile.enum";
import LicensesSupervision from "@views/core/admin/licenses/LicensesSupervision";
import { useUnit } from "effector-react";
import { authenticationStore } from "@state/auth/AuthStore";

export const licenseColorVariant = (status: LicenseStatus) => {
  if (status === LicenseStatus.EXPIRED) {
    return "danger";
  } else if (status === LicenseStatus.ASSIGNED) {
    return "success";
  }
  return "white";
};

const LicensesScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const authenticationContext = useUnit(authenticationStore);

  return (
    <PageLayout container title={t("licenses.title")}>
      <>
        <MyLicenses />
        <MyAuthorizations />
        <LicensesManagement />
        {authenticationContext?.user &&
          isProfileAmong(
            UserProfile.SUPERVISOR,
            authenticationContext.user.profiles,
          ) && <LicensesSupervision />}
        <BasicCard className="mt-48">
          <div className="d-flex align-items-center justify-content-end flex-wrap gap-16">
            <p className="flex-grow-1 m-0 text-transparent-card">
              <br />
              <br />
            </p>
          </div>
        </BasicCard>
      </>
    </PageLayout>
  );
};

export default LicensesScreen;
