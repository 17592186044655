import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { requestGetDocumentContent } from "@state/transactions/TransactionEffects";
import { DocumentContentResponseDto } from "@state/transactions/dto/response/document.content.response.dto";
import DisplayDocument from "@views/core/document/DisplayDocument";
import { useParams } from "react-router";
import { toastError } from "@utils/helpers/toast-helper";
import { useTranslation } from "react-i18next";

const DisplayDocumentContainer: FunctionComponent = (): ReactElement => {
  const [document, setDocument] = useState<DocumentContentResponseDto>();
  const { t } = useTranslation();
  const [pdfError, setPdfError] = useState<boolean>(true);

  const { transactionId, documentId } = useParams<{
    transactionId: string;
    documentId: string;
  }>();

  useEffect(() => {
    if (transactionId && documentId) {
      void requestGetDocumentContent({
        upperEntityId: transactionId,
        id: documentId + "/response-file",
      });
    }
  }, [transactionId, documentId]);

  const downloadDocument = (d: DocumentContentResponseDto) => {
    const downloadLink = window.document.createElement("a");
    downloadLink.href = `data:${d.mimeType};base64,${d.base64Data}`;
    downloadLink.download = d.filename;
    downloadLink.click();
  };

  useEffect(() => {
    return requestGetDocumentContent.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setDocument(result.data);

        downloadDocument(result.data);
      } else if (result.responseCode === 401) {
        toastError(t("error.401"));
      }
    });
  });

  return (
    <DisplayDocument
      id={documentId}
      className="Preview"
      filename={document?.filename}
      mimeType={document?.mimeType}
      data={document?.base64Data}
      pdfError={pdfError}
      setPdfError={setPdfError}
      showControls={true}
      // height={height}
      // width={width}
    />
  );
};

export default DisplayDocumentContainer;
