import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import PageLayout from "@components/core/layouts/PageLayout";
import parse from "html-react-parser";
import BasicButton from "@components/core/buttons/BasicButton";
import { ROUTES } from "@routes/Routes";
import { LINK_DEFAULT_TITLE } from "@utils/Constant";
import BasicLink from "@components/core/links/BasicLink";

const CookiesScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useNavigate();

  const translate = (...values: string[]): string => {
    const fields = values.map((value: string): string => `.${value}`).join("");
    return t(`cookies${fields}`);
  };

  return (
    <PageLayout title={t("cookies.title")} contentInCard>
      <>
        <h1>{translate("subtitle")}</h1>
        <hr />

        <h3 className="mt-48">{translate("what", "title")}</h3>
        <p>{translate("what", "definition")}</p>

        <p>
          <BasicLink
            to={translate("what", "link", "value")}
            target="_blank"
            title={LINK_DEFAULT_TITLE}
          >
            {translate("what", "link", "name")}
          </BasicLink>
        </p>

        <h3 className="mt-48">{translate("which", "title")}</h3>

        <p>{parse(translate("which", "body"))}</p>
        {parse(translate("which", "content"))}

        <p className="d-flex flex-column align-items-center justify-content-center">
          <BasicButton
            text={t("buttons.back")}
            variant="primary-outlined"
            className="mt-24"
            onClick={() => {
              void history(ROUTES.connected.home.generate());
            }}
          />
        </p>
      </>
    </PageLayout>
  );
};

export default CookiesScreen;
