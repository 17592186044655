import { ChangeEvent, FunctionComponent } from "react";
import BasicButton from "@components/core/buttons/BasicButton";
import { PlusOutlined } from "@ant-design/icons";

interface Props {
  id: string;
  text: string;
  handleFileSubmit: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ImportFileButton: FunctionComponent<Props> = (props) => {
  const { id, text, handleFileSubmit } = props;

  return (
    <>
      <BasicButton
        size="sm"
        variant="primary-outlined"
        icon={<PlusOutlined />}
        iconRight
        onClick={() => {
          const input = document.getElementById(id);
          input?.click();
        }}
        text={text}
      />
      <input
        id={id}
        type="file"
        name="fileInput"
        hidden
        onChange={(e) => {
          handleFileSubmit(e);
        }}
        accept=".csv,.xlsx,.xls"
      />
    </>
  );
};

export default ImportFileButton;
