import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import BasicTable from "@components/core/tables/BasicTable";
import {
  requestGetSupervisedLicenses,
  requestSuperviseLicenseByFile,
  requestSuperviseLicenseByKey,
} from "@state/licenses/LicensesEffects";
import Section from "@components/core/section/Section";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "@utils/helpers/toast-helper";
import { SupervisedLicenseITemResponseDto } from "@state/licenses/dto/response/supervised.license.item.response.dto";
import { columns as licensesSupervisionColumns } from "@views/core/admin/licenses/columns/LicensesSupervisionColumns";
import { useForm } from "antd/lib/form/Form";
import { Form } from "antd";
import { formValidateTriggers } from "@utils/Constant";
import LicenseKeyField from "@components/core/inputs/LicenseKeyField";
import { licenseColorVariant } from "@views/core/admin/licenses/LicensesScreen";
import { readFileAsFormData } from "@utils/helpers/file-helper";
import ImportFileButton from "@components/core/buttons/ImportFileButton";
import { SorterResult } from "antd/es/table/interface";

const LicensesSupervision: FunctionComponent = () => {
  const { t } = useTranslation();

  const [form] = useForm();

  const [supervisedLicensesLoaded, setSupervisedLicensesLoaded] =
    useState<boolean>(false);

  const [sorter, setSorter] = useState<
    | SorterResult<SupervisedLicenseITemResponseDto>
    | SorterResult<SupervisedLicenseITemResponseDto>[]
  >({
    columnKey: "key",
    order: "ascend",
  });

  const [supervisedLicenses, setSupervisedLicenses] = useState<
    SupervisedLicenseITemResponseDto[]
  >([]);

  const fetchSupervisedLicenses = useCallback(() => {
    setSupervisedLicensesLoaded(false);
    void requestGetSupervisedLicenses({
      sorter: sorter,
    });
  }, [sorter]);

  useEffect(() => {
    fetchSupervisedLicenses();
  }, [fetchSupervisedLicenses]);

  useEffect(() => {
    return requestGetSupervisedLicenses.done.watch(({ result }) => {
      setSupervisedLicensesLoaded(true);
      if (result.ok && result.data) {
        setSupervisedLicenses(result.data);
      } else {
        toastError(t("licenses.errors.supervision.loadFailed"));
      }
    });
  });

  useEffect(() => {
    return requestSuperviseLicenseByKey.done.watch(({ result }) => {
      if (result.ok) {
        toastSuccess(t("licenses.success.supervise"));
        form.resetFields();
        fetchSupervisedLicenses();
      } else if (result.responseCode === 409) {
        toastError(t("licenses.errors.supervise.licenceAlreadySupervised"));
      } else {
        toastError(t("licenses.errors.supervise.superviseLicenseFailed"));
      }
    });
  });

  useEffect(() => {
    return requestSuperviseLicenseByFile.done.watch(({ result }) => {
      if (result.ok) {
        fetchSupervisedLicenses();
        if (result.responseCode === 200) {
          toastSuccess(t("licenses.success.supervise-by-file"));
        } else {
          toastWarning(t("licenses.warning.supervise-by-file"));
        }
      } else {
        toastError(t("licenses.errors.supervise.superviseLicenseByFileFailed"));
      }
    });
  });

  const handleSupervise = (licenseKey?: string) => {
    if (licenseKey) {
      void requestSuperviseLicenseByKey({
        dto: {
          licenseKey: licenseKey,
        },
      });
    } else {
      toastError(t("licenses.errors.supervise.emptyKey"));
    }
  };

  const handleSuperviseLicenses = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const fileFormData = await readFileAsFormData(event);
    void requestSuperviseLicenseByFile({
      dto: fileFormData,
    });
  };

  return (
    <Section
      title={t("licenses.licensesSupervision.title")}
      className="mb-48"
      rightContent={
        <ImportFileButton
          id="superviseFileInput"
          text={t("licenses.licensesSupervision.supervise")}
          handleFileSubmit={(e) => void handleSuperviseLicenses(e)}
        />
      }
    >
      <Form
        {...formValidateTriggers}
        form={form}
        layout="vertical"
        className="table-licenses-management"
      >
        <>
          <BasicTable
            dataSource={supervisedLicenses}
            rowKey={(record: SupervisedLicenseITemResponseDto) => record.key}
            columns={licensesSupervisionColumns}
            rowClassName={(record: SupervisedLicenseITemResponseDto) =>
              `row-${licenseColorVariant(record.status)}`
            }
            isLoading={!supervisedLicensesLoaded}
            onChange={(pagination, filters, sorter) => {
              if (
                (sorter as SorterResult<SupervisedLicenseITemResponseDto>)
                  .column
              ) {
                setSorter(sorter);
              }
            }}
          />
          <LicenseKeyField
            form={form}
            module="licenses.form"
            field="licenseNumber"
            onClick={(key?: string) => {
              handleSupervise(key);
            }}
          />
        </>
      </Form>
    </Section>
  );
};

export default LicensesSupervision;
