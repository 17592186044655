import { useEffect, useMemo, JSX } from "react";
import { useUnit } from "effector-react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Spin } from "antd";
import { ROUTES } from "@routes/Routes";
import { authenticationStore } from "@state/auth/AuthStore";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import { usePreferences } from "@/state/preferences/Preference";
import { authHelper } from "@utils/helpers/auth-helper";
import { toastError } from "@utils/helpers/toast-helper";
import { useTranslation } from "react-i18next";
import { ApiErrors } from "@utils/ApiErrors";

const AuthLayout = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const authenticationContext = useUnit(authenticationStore);

  const { isLoading: isPreferencesLoading } = usePreferences();

  const loginRedirect = useMemo(
    () =>
      ROUTES.auth.login.generate({
        callback: location.pathname,
      }),
    [location],
  );

  const expiredPasswordRedirect = useMemo(
    () => ROUTES.connected.account.expiredPassword.generate(),
    [],
  );

  useEffect(() => {
    requestGetUserInfo({})
      .then((ar) => {
        if (ar.ok && ar.data) {
          authHelper.setAuthenticatedUser(ar.data, authenticationContext);

          if (ar.data.passwordExpired) {
            void navigate(expiredPasswordRedirect);
          }
        } else {
          if (ar.errorCode === ApiErrors.DISCONNECTION) {
            toastError(t("error.401"));
          }
          void navigate(loginRedirect);
        }
      })
      .catch(() => {
        void navigate(loginRedirect);
      });
  }, [
    navigate,
    loginRedirect,
    expiredPasswordRedirect,
    authenticationContext,
    t,
  ]);

  if (!authenticationContext?.user || isPreferencesLoading) {
    return (
      <div className="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }

  return <Outlet />;
};

export default AuthLayout;
