export enum LicenseStatus {
  CREATED = "CREATED",
  OWNED = "OWNED",
  PROPOSED = "PROPOSED",
  // TODO : PASSER EN "IN-USE" LORSQU'ON POURRA FAIRE UNE MEP DE L'APPLICATION MOBILE
  ASSIGNED = "ASSIGNED",
  QUEUED = "QUEUED",
  NOT_ASSIGNED = "NOT_ASSIGNED",
  EXPIRED = "EXPIRED",
  ARCHIVED = "ARCHIVED",
}

export interface LicenseStatusProperties {
  label: string;
  variant: string;
}

export const licenseStatusProperties: Record<
  LicenseStatus,
  LicenseStatusProperties
> = {
  [LicenseStatus.CREATED]: {
    label: "licenses.labels.status.CREATED",
    variant: "grey",
  },
  [LicenseStatus.OWNED]: {
    label: "licenses.labels.status.OWNED",
    variant: "secondary",
  },
  [LicenseStatus.PROPOSED]: {
    label: "licenses.labels.status.PROPOSED",
    variant: "grey",
  },
  [LicenseStatus.ASSIGNED]: {
    label: "licenses.labels.status.IN_USE",
    variant: "success",
  },
  [LicenseStatus.QUEUED]: {
    label: "licenses.labels.status.QUEUED",
    variant: "primary",
  },
  [LicenseStatus.NOT_ASSIGNED]: {
    label: "licenses.labels.status.NOT_ASSIGNED",
    variant: "secondary",
  },
  [LicenseStatus.EXPIRED]: {
    label: "licenses.labels.status.EXPIRED",
    variant: "danger",
  },
  [LicenseStatus.ARCHIVED]: {
    label: "licenses.labels.status.ARCHIVED",
    variant: "grey",
  },
};
