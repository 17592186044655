import {
  differenceInYearsMonthsDays,
  elapsedDaysInPercent,
} from "@utils/helpers/date-helper";
import BasicProgress from "@components/core/progress/BasicProgress";
import i18n from "i18next";
import { UserLicenseResponseDto } from "@state/licenses/dto/response/user.license.response.dto";
import { SupervisedLicenseITemResponseDto } from "@state/licenses/dto/response/supervised.license.item.response.dto";
import DateFormat from "@utils/DateFormat";
import { LicenseStatus } from "@utils/enums/license.enum";

export const renderRemainingDurationCell = (
  record: UserLicenseResponseDto | SupervisedLicenseITemResponseDto,
) => {
  return record.activationDate && record.expirationDate ? (
    <div className="d-flex flex-column gap-10">
      <p className="m-0 d-flex align-items-center justify-content-between gap-8">
        <span>
          {differenceInYearsMonthsDays(
            new Date(),
            new Date(record.expirationDate),
          )}
        </span>
      </p>
      <BasicProgress
        className="inverted-progress"
        percent={elapsedDaysInPercent(
          record.activationDate,
          record.expirationDate,
        )}
      />
    </div>
  ) : (
    <div className="d-flex flex-column gap-10">
      <span>
        {i18n.t(`common.${record.durationUnit}`, {
          count: record.durationValue,
        })}
      </span>
      <BasicProgress percent={100} disabled />
    </div>
  );
};

export const renderPeriodCell = (
  record: UserLicenseResponseDto | SupervisedLicenseITemResponseDto,
) => {
  if (record.activationDate && record.expirationDate) {
    return `${DateFormat.dashboardDate(new Date(record.activationDate))} ${i18n.t("licenses.to")} ${DateFormat.dashboardDate(new Date(record.expirationDate))}`;
  }

  return record.status === LicenseStatus.QUEUED
    ? i18n.t("licenses.waitingActivation")
    : i18n.t("licenses.waitingAssignment");
};
