import i18n, { t } from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import BasicButton from "@components/core/buttons/BasicButton";
import { CloseOutlined } from "@ant-design/icons";
import { requestRejectLicense } from "@state/licenses/LicensesEffects";
import { showConfirm } from "@components/core/modals/ConfirmModal";
import { UserLicenseResponseDto } from "@state/licenses/dto/response/user.license.response.dto";
import {
  renderPeriodCell,
  renderRemainingDurationCell,
} from "@views/core/admin/licenses/columns/DurationCells";

export const columns: ColumnsType<UserLicenseResponseDto> = [
  {
    title: i18n.t("licenses.myLicenses.table.period"),
    className: "change-color-with-status",
    render: renderPeriodCell,
  },
  {
    title: i18n.t("licenses.myLicenses.table.remainingDuration"),
    className: "change-color-with-status",
    width: 202,
    showSorterTooltip: false,
    render: renderRemainingDurationCell,
  },
  {
    title: i18n.t("licenses.myLicenses.table.assignBy"),
    showSorterTooltip: false,
    render: (record: UserLicenseResponseDto) =>
      record.managerLastName && record.managerFirstName
        ? `${record.managerFirstName} ${record.managerLastName}`
        : "",
  },
  {
    title: "",
    width: 188,
    render: (record: UserLicenseResponseDto) => (
      <div className="d-flex align-items-center justify-content-end">
        <BasicButton
          size="sm"
          variant="transparent"
          icon={<CloseOutlined />}
          iconRight
          text={i18n.t("buttons.reject")}
          onClick={() => {
            void showConfirm({
              title: t("licenses.actions.reject.title"),
              content: t("licenses.actions.reject.content"),
            }).then((confirmed: boolean) => {
              if (confirmed) {
                void requestRejectLicense({ upperEntityId: record.id });
              }
            });
          }}
        />
      </div>
    ),
  },
];
