import { FunctionComponent } from "react";
import InputFormField from "@components/core/inputs/InputFormField";
import BasicButton from "@components/core/buttons/BasicButton";
import { PlusOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd/es/form/hooks/useForm";

interface Props {
  form: FormInstance;
  module: string;
  field: string;
  onClick: (key?: string) => void;
}

const LicenseKeyField: FunctionComponent<Props> = (props) => {
  const { form, module, field, onClick } = props;

  return (
    <div className="table-licenses-management-add-row">
      <InputFormField
        form={form}
        module={module}
        field={field}
        className="mb-0"
      />
      <BasicButton
        size="sm"
        variant="primary-outlined"
        disabled={form.getFieldValue(field) === ""}
        onClick={() => {
          onClick(form.getFieldValue(field) as string | undefined);
        }}
        icon={<PlusOutlined />}
      />
    </div>
  );
};

export default LicenseKeyField;
