import i18n from "i18next";
import { DateTime } from "luxon";

export function differenceInYearsMonthsDays(date1: Date, date2: Date): string {
  if (date1 > date2) {
    return `0 ${i18n.t("common.DAY")}`;
  }
  let years = date2.getFullYear() - date1.getFullYear();
  let months = date2.getMonth() - date1.getMonth();
  let days = date2.getDate() - date1.getDate();

  if (months < 0) {
    years--;
    months += 12;
  }

  if (days < 0) {
    months--;
    const previousMonth = new Date(date2.getFullYear(), date2.getMonth(), 0);
    days += previousMonth.getDate();
  }

  if (months < 0) {
    years--;
    months += 12;
  }

  const yearsDisplay =
    years > 0 ? `${i18n.t("common.YEAR", { count: years })} ` : "";
  const monthsDisplay =
    months > 0 ? `${i18n.t("common.MONTH", { count: months })} ` : "";
  const daysDisplay = days > 0 ? i18n.t("common.DAY", { count: days }) : "";

  return `${yearsDisplay}${monthsDisplay}${daysDisplay}`;
}

export function elapsedDaysInPercent(
  activationDate: string,
  expirationDate: string,
): number {
  const activationDateParsed = DateTime.fromISO(activationDate);
  const expirationDateParsed = DateTime.fromISO(expirationDate);
  const today = DateTime.now().startOf("day");

  if (activationDateParsed > today || today > expirationDateParsed) {
    return 100;
  }

  const totalTimeInDay = expirationDateParsed.diff(activationDateParsed, [
    "days",
  ]);

  const elapsedTimeInDay = today.diff(activationDateParsed);

  return (elapsedTimeInDay.days / totalTimeInDay.days) * 100;
}
