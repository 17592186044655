import i18n from "i18next";
import * as t from "io-ts";
import {
  DecoderTypeComputer,
  validateServerResponse,
} from "@utils/rest/ServerResponseValidator";

export interface FieldError {
  scope: string;
  field: string;
  message: string;
  errorCode: string;
  constraints?: string;
}

export interface ParsedResponse<T> {
  data: T | undefined;
  responseCode: number | undefined;
  errorCode?: string;
  errorMessage?: string;
  fieldErrors?: FieldError[];
  globalErrors?: FieldError[];
  attempts?: number;
  maxAttempts?: number;
  ok: boolean;
}

export interface ErrorResponse {
  type: string;
  title: string;
  status: number;
  detail?: string;
  instance?: string;
  errorCategory?: string;
  errorCode?: string;
  invalidParams?: FieldError[];
  attempts?: number;
  maxAttempts?: number;
}

export class ServerResponseParser<T> {
  parseResponse<
    PrimaryType extends t.Props | t.Mixed = t.Props,
    DecoderType extends
      DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
  >(response: Response, decoder?: DecoderType): Promise<ParsedResponse<T>> {
    if (response.ok) {
      if (response.status === 204) {
        return Promise.resolve({
          responseCode: response.status,
          data: undefined,
          ok: true,
        } as ParsedResponse<T>);
      }

      return response.text().then((text: string) => {
        const inputData = text && (JSON.parse(text) as PrimaryType);

        const data = validateServerResponse<PrimaryType, DecoderType>(
          inputData,
          decoder,
        ) as T;

        return {
          responseCode: response.status,
          errorMessage: undefined,
          errorCode: undefined,
          data,
          ok: true,
        };
      });
    } else {
      return response.text().then((text: string) => {
        const json = (text && JSON.parse(text)) as ErrorResponse;
        const result = {
          responseCode: response.status,
          data: undefined,
          errorCode: json.errorCode,
          ok: false,
          attempts: json.attempts,
          maxAttempts: json.maxAttempts,
        };

        if (response.status === 400) {
          return {
            ...result,
            errorMessage: json.detail
              ? json.detail
              : i18n.t("error.bad-request"),
            fieldErrors: json.invalidParams?.filter(
              (error) => error.scope === "FIELD",
            ),
            globalErrors: json.invalidParams?.filter(
              (error) => error.scope === "GLOBAL",
            ),
          };
        } else {
          return {
            ...result,
            errorMessage: json.detail
              ? (json.title ? json.title + " : " : "") + json.detail
              : json.title,
          };
        }
      });
    }
  }
}
