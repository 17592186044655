import { ColumnsType } from "antd/lib/table/interface";
import { SupervisedLicenseITemResponseDto } from "@state/licenses/dto/response/supervised.license.item.response.dto";
import i18n from "i18next";
import {
  renderPeriodCell,
  renderRemainingDurationCell,
} from "@views/core/admin/licenses/columns/DurationCells";

export const columns: ColumnsType<SupervisedLicenseITemResponseDto> = [
  {
    title: i18n.t("licenses.licensesSupervision.table.licenseKey"),
    key: "key",
    sorter: true,
    dataIndex: "key",
  },
  {
    title: i18n.t("licenses.licensesSupervision.table.mangerName"),
    key: "managerName",
    sorter: true,
    render: (record: SupervisedLicenseITemResponseDto) => {
      return record.managerFirstName && record.managerLastName
        ? `${record.managerFirstName} ${record.managerLastName}`
        : "";
    },
  },
  {
    title: i18n.t("licenses.licensesSupervision.table.managerEmail"),
    key: "managerEmail",
    sorter: true,
    dataIndex: "managerEmail",
  },
  {
    title: i18n.t("licenses.licensesSupervision.table.holderName"),
    key: "holderName",
    sorter: true,
    render: (record: SupervisedLicenseITemResponseDto) => {
      return record.holderFirstName && record.holderLastName
        ? `${record.holderFirstName} ${record.holderLastName}`
        : "";
    },
  },
  {
    title: i18n.t("licenses.licensesSupervision.table.holderEmail"),
    key: "holderEmail",
    sorter: true,
    dataIndex: "holderEmail",
  },
  {
    title: i18n.t("licenses.licensesSupervision.table.activationPeriod"),
    key: "activationPeriod",
    sorter: true,
    className: "change-color-with-status",
    render: renderPeriodCell,
  },
  {
    title: i18n.t("licenses.licensesSupervision.table.remainingDuration"),
    key: "remainingDuration",
    className: "change-color-with-status",
    sorter: true,
    render: renderRemainingDurationCell,
  },
];
