import { FunctionComponent, JSX } from "react";

interface FloatingLabelProps {
  label: string | JSX.Element;
  children: string | JSX.Element;
  className?: string;
}

const FloatingLabel: FunctionComponent<FloatingLabelProps> = (
  props: FloatingLabelProps,
) => {
  const { label, children, className = "" } = props;

  return (
    <div className={`range-picker-label-container ${className}`}>
      {children}
      <label className="range-picker-title">{label}</label>
    </div>
  );
};

export default FloatingLabel;
