import { FunctionComponent, ReactNode } from "react";
import i18n from "i18next";
import { useSearchParams } from "react-router";
import PageLayout from "@components/core/layouts/PageLayout";
import { userSupportEmail } from "@/main";

const errorMessageSearchParam = "errorMessage";

type enrollmentType = "idCard" | "sso";

const computeTitle = (
  type: enrollmentType,
  errorMessageParam: string | null,
) =>
  errorMessageParam
    ? i18n.t(`deeplink.enrollment.${type}.error.title`)
    : i18n.t(`deeplink.enrollment.${type}.success.title`);

const computeDescription = (
  type: enrollmentType,
  errorMessageParam: string | null,
): ReactNode => {
  switch (type) {
    case "idCard":
      return computeIdCardDescription(errorMessageParam);
    case "sso":
      return computeSsoDescription(errorMessageParam);
    default:
      return undefined;
  }
};

const computeIdCardDescription = (
  errorMessageParam: string | null,
): ReactNode => {
  return errorMessageParam
    ? computeIdCardErrorDescription()
    : computeIdCardSuccessDescription();
};

const computeIdCardErrorDescription = (): ReactNode => (
  <div>
    <div className="text-center mb-16">
      {i18n.t("deeplink.enrollment.idCard.error.description.details")}
    </div>
    <ul>
      <li>
        {i18n.t("deeplink.enrollment.idCard.error.description.verification.1")}
      </li>
      <li>
        {i18n.t("deeplink.enrollment.idCard.error.description.verification.2")}
      </li>
    </ul>
    <div className="text-center font-weight-bold mb-16">
      {i18n.t("deeplink.closeTab")}
    </div>
    <div className="text-center mb-16">
      {i18n.t("deeplink.support")}
      <a href={userSupportEmail}>{userSupportEmail}</a>.
    </div>
  </div>
);

const computeIdCardSuccessDescription = (): ReactNode => {
  return (
    <div>
      <div className="text-center mb-16">
        {i18n.t("deeplink.enrollment.idCard.success.description.delay")}
      </div>
      <div className="text-center mb-16 font-weight-bold">
        {i18n.t("deeplink.closeTab")}
      </div>
      <div className="text-center mb-16">
        {i18n.t("deeplink.support")}
        <a href={userSupportEmail}>{userSupportEmail}</a>.
      </div>
      <div className="text-center font-weight-bold">
        {i18n.t(
          "deeplink.enrollment.idCard.success.description.security.title",
        )}
      </div>
      <div className="text-center">
        {i18n.t(
          "deeplink.enrollment.idCard.success.description.security.details",
        )}
      </div>
    </div>
  );
};

const computeSsoDescription = (errorMessageParam: string | null): ReactNode => {
  return errorMessageParam
    ? computeSsoErrorDescription()
    : computeSsoSuccessDescription();
};

const computeSsoErrorDescription = (): ReactNode => (
  <div>
    <div className="text-center mb-16">
      {i18n.t("deeplink.enrollment.sso.error.description")}
    </div>
    <div className="text-center font-weight-bold mb-16">
      {i18n.t("deeplink.closeTab")}
    </div>
    <div className="text-center mb-16">
      {i18n.t("deeplink.support")}
      <a href={userSupportEmail}>{userSupportEmail}</a>.
    </div>
  </div>
);

const computeSsoSuccessDescription = (): ReactNode => (
  <div>
    <div className="text-center mb-16">
      {i18n.t("deeplink.enrollment.sso.success.description")}
    </div>
    <div className="text-center font-weight-bold mb-16">
      {i18n.t("deeplink.closeTab")}
    </div>
    <div className="text-center">
      {i18n.t("deeplink.support")}
      <a href={userSupportEmail}>{userSupportEmail}</a>.
    </div>
  </div>
);

interface Props {
  type: enrollmentType;
}

const EnrollmentScreen: FunctionComponent<Props> = ({ type }) => {
  const [searchParams] = useSearchParams();

  return (
    <PageLayout
      container={false}
      contentInCard
      bodyClassName="d-flex align-items-center justify-content-center h-100"
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h1 className="mt-16 mb-32 text-center">
          {computeTitle(type, searchParams.get(errorMessageSearchParam))}
        </h1>
        {computeDescription(type, searchParams.get(errorMessageSearchParam))}
      </div>
    </PageLayout>
  );
};

export default EnrollmentScreen;
