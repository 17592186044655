import { FunctionComponent, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { requestConfirmRegistration } from "@state/auth/AuthEffects";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { ROUTES } from "@routes/Routes";
import LoginLayout from "@components/core/layouts/LoginLayout";
import { Spin } from "antd";

const ConfirmRegistrationScreen: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const token = new URLSearchParams(location.search).get("token");
  const userId = new URLSearchParams(location.search).get("userId");

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userId && token) {
      requestConfirmRegistration({
        dto: {
          userId,
          token,
        },
      })
        .then((authResult: ParsedResponse<void>) => {
          if (authResult.ok) {
            toastSuccess(t("registration.confirmation.messages.success"));
            void navigate(ROUTES.auth.login.generate());
          } else {
            if (authResult.errorCode) {
              toastError(
                t(
                  `registration.confirmation.messages.errors.${authResult.errorCode}`,
                ),
              );
            } else {
              toastError(t("registration.confirmation.messages.error"));
            }
          }
        })
        .catch(() => {
          toastError(t("login.messages.error"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userId, token, t, navigate]);

  return (
    <LoginLayout title={t("registration.title")}>
      <>
        {isLoading && (
          <div className="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
            <Spin />
          </div>
        )}
      </>
    </LoginLayout>
  );
};

export default ConfirmRegistrationScreen;
