import { FunctionComponent, useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/core/buttons/BasicButton";
import {
  requestRecoverPassword,
  requestValidateResetToken,
} from "@state/auth/AuthEffects";
import { useForm } from "antd/lib/form/Form";
import { logout } from "@state/auth/AuthEvents";
import { extractStringFrom } from "@utils/helpers/query-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { passwordHelper } from "@utils/helpers/password-helper";
import InputFormField from "@components/core/inputs/InputFormField";
import SubmitButton from "@components/core/buttons/SubmitButton";
import PasswordValidationTooltip from "@components/core/tooltips/PasswordValidationTooltip";
import { formHelper } from "@utils/helpers/form-helper";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import { formValidateTriggers } from "@utils/Constant";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import { ROUTES } from "@routes/Routes";
import LoginLayout from "@components/core/layouts/LoginLayout";

interface SetPasswordProps {
  isCreation?: boolean;
}

interface SetPasswordFormData {
  credential: string;
  confirmCredential: string;
}

const SetPasswordComponent: FunctionComponent<SetPasswordProps> = (
  props: SetPasswordProps,
) => {
  const { isCreation } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = useForm();

  const InitialValues = {
    credential: "",
    confirmCredential: "",
  };

  const [tokenValue, setTokenValue] = useState<string | null | undefined>();
  const [userIdValue, setUserIdValue] = useState<string | null | undefined>();
  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordStrengthConstraints, setPasswordStrengthConstraints] =
    useState<PasswordStrengthConstraintsDetailsResponseDto>();

  function getParams(): {
    token: string | undefined;
    userId: string | undefined;
  } {
    const searchParams = new URLSearchParams(window.location.search);

    const token = searchParams.get("token");
    const userId = searchParams.get("userId");

    const tokenValue = extractStringFrom(token);
    const userIdValue = extractStringFrom(userId);
    return { token: tokenValue, userId: userIdValue };
  }

  useEffect(() => {
    const { token, userId } = getParams();
    // remove token from url to prevent http referer leakage
    void navigate(window.location.pathname, { replace: true });

    void requestValidateResetToken({
      dto: {
        token: token ?? "",
        userId: userId ?? "",
      },
    }).then((result) => {
      if (result.ok) {
        setTokenValue(token);
        setUserIdValue(userId);
      } else {
        toastError(t("registration.form.messages.timeout"));
        void navigate(ROUTES.auth.login.generate());
      }
    });
  }, [navigate, t]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (
      value === "" ||
      (passwordStrengthConstraints &&
        passwordHelper.validate(value, passwordStrengthConstraints))
    ) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  const handleSubmit = (values: SetPasswordFormData) => {
    requestRecoverPassword({
      dto: {
        token: tokenValue ?? "",
        userId: userIdValue ?? "",
        credential: values.credential,
        confirmCredential: values.confirmCredential,
      },
    })
      .then((result: ParsedResponse<void>) => {
        if (result.ok) {
          toastSuccess(
            t(`${isCreation ? "create" : "reset"}-password.messages.success`),
          );
          logout();
          void navigate(ROUTES.auth.login.generate());
        } else {
          formHelper.handleFormErrors(
            "users.edit.form.main.fields",
            form,
            result,
          );
        }
      })
      .catch((error: unknown): void => {
        toastError((error as Error).message);
      });
  };

  useEffect(() => {
    return requestValidateResetToken.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPasswordStrengthConstraints(result.data);
      }
    });
  });

  return (
    <LoginLayout title={t(`${isCreation ? "create" : "reset"}-password.title`)}>
      <>
        <Form
          onFinish={handleSubmit}
          form={form}
          initialValues={InitialValues}
          {...formValidateTriggers}
        >
          <InputFormField
            form={form}
            onChange={onChange}
            module="account.set-password"
            type="password"
            field="credential"
            required
          />
          {passwordValidationFailed && passwordStrengthConstraints && (
            <div data-test="error-credential">
              <PasswordValidationTooltip
                password={password}
                strengthConstraints={passwordStrengthConstraints}
              />
            </div>
          )}
          <InputFormField
            form={form}
            module="account.set-password"
            type="password"
            field="confirmCredential"
            className="mb-16"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("credential") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        "account.set-password.fields.credential.errors.unmatch",
                      ),
                    ),
                  );
                },
              }),
            ]}
            required
          />
          <div className="pt-8 d-flex flex-column align-items-stretch gap-24">
            <SubmitButton
              module="account.set-password.form"
              isSubmitting={false}
              label="save"
            />
            <BasicButton
              text={t("buttons.back")}
              variant="transparent"
              onClick={() => {
                void navigate(ROUTES.auth.login.generate());
              }}
            />
          </div>
        </Form>
      </>
    </LoginLayout>
  );
};

export default SetPasswordComponent;
